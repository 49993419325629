import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// Define the state and actions types
interface PersistStore {
  value: boolean | null;
  setValue: (newValue: boolean | null) => void;
}

// Factory function to create a store with a custom persist name
const createPersistStore = (persistName: string) =>
  create<PersistStore>()(
    persist(
      (set) => ({
        value: null, // Initial state
        setValue: (newValue) => set({ value: newValue }), // Action to update the state
      }),
      {
        name: persistName, // Dynamic key for localStorage
      }
    )
  );

export { createPersistStore };
